import { css } from 'linaria';

import { colors } from '../../tokens';
import fluid from '../../mixins/fluid.style';

export const container = css`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  padding: 2rem 0 0;
  width: 100%;
  background: ${colors.darkGrey};

  @media screen and (min-width: 800px) {
    padding-top: 6.8rem;
  }
`;

export const grid = css`
  list-style: none;
  margin: 0;
`;

export const imageGroup = css`
  width: 100%;

  > * + * {
    margin-top: ${fluid(0.5, 0.75)};
  }
`;

export const imageContainer = css`
  max-height: 100%;
  overflow: hidden;
  position: relative;

  :hover,
  :focus {
    cursor: pointer;
  }

  :hover > *:not(button),
  :focus > *:not(button) {
    transition: transform 600ms ease-in-out;
    transform: scale(1.05);
  }

  :hover > button,
  :focus > button {
    opacity: 0.8;
  }
`;

export const enlargeButton = css`
  background: transparent;
  border: 0;
  bottom: 0.5rem;
  color: ${colors.white};
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  transition: opacity 400ms ease-in;
  z-index: 5;

  :hover,
  :focus {
    color: ${colors.pink};
    cursor: pointer;
  }
`;

export const enlargeIcon = css`
  height: 1rem;
  width: 1rem;
`;

export const closeButton = css`
  background: transparent;
  border: 0;
  color: ${colors.white};
  opacity: 0.8;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  z-index: 100;

  :hover,
  :focus {
    color: ${colors.pink};
    cursor: pointer;
    opacity: 1;
  }
`;

export const closeIcon = css`
  height: 2.5rem;
  width: 2.5rem;
`;

export const row = css`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 1rem;

  @media screen and (min-width: 800px) {
    grid-gap: 1.5rem;
    margin-bottom: 1.5rem;
  }

  :first-child > * {
    grid-column: 1 / 13;
  }

  :nth-child(2) > *:first-child {
    grid-column: 1 / 9;
  }

  :nth-child(2) > *:nth-child(2) {
    grid-column: 9 / 13;
  }
`;

export const modal = css`
  bottom: ${fluid(1, 2.5)};
  left: ${fluid(1, 2.5)};
  position: absolute;
  padding: 0;
  right: ${fluid(1, 2.5)};
  top: ${fluid(1, 2.5)};
`;

export const modalOverlay = css`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`;
