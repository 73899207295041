import { useStaticQuery, graphql } from 'gatsby';
//import GatsbyImage from 'gatsby-image';
import GatsbyImage from "gatsby-image/withIEPolyfill";
import React from 'react';

import { items, itemimage, shirtcouple, itempatch, pricetag, link, pricenote  } from './shop.style';

const Items = () => {
  const data = useStaticQuery(graphql`
    query {
      t1front: file(relativePath: { eq: "tshirt1front.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      t1back: file(relativePath: { eq: "tshirt1back.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      t2front: file(relativePath: { eq: "tshirt2front.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      t2back: file(relativePath: { eq: "tshirt2back.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      patch: file(relativePath: { eq: "patch.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={items}>
      <a href='https://shop.mvlouisemichel.org/product/border-farce-t-shirt/1' className={link} target="_blank" rel="noreferrer">
        <div>
          <div className={shirtcouple}>
            <GatsbyImage {...data.t1front.childImageSharp} className={itemimage} objectFit="contain" />
            <GatsbyImage {...data.t1back.childImageSharp} className={itemimage} objectFit="contain" />
          </div>
          <p className={pricetag}>
            Border Farce T-Shirt<br />
            £20.00
          </p>
        </div>
      </a>
      <a href='https://shop.mvlouisemichel.org/product/louise-michel-solidarity-t-shirt/2' className={link} target="_blank" rel="noreferrer">
        <div>
          <div className={shirtcouple}>
            <GatsbyImage {...data.t2front.childImageSharp} className={itemimage} objectFit="contain" />
            <GatsbyImage {...data.t2back.childImageSharp} className={itemimage} objectFit="contain" />
          </div>
          <p className={pricetag}>
            Louise Michel Solidarity T-Shirt<br />
            £20.00
          </p>
        </div>
      </a>
      <a href='https://shop.mvlouisemichel.org/product/louise-michel-patch/3' className={`${link} ${itempatch}`} target="_blank" rel="noreferrer">
        <div>
          <GatsbyImage {...data.patch.childImageSharp} style={{margin: "auto"}} className={itemimage} objectFit="contain" />
          <p className={pricetag}>
            Louise Michel Patches<br />
            £10.00
          </p>
          <p className={pricenote}>
            Patches sold as a pair. Hoodie not included, serving suggestion only
          </p>
        </div>
      </a>
    </div>
  );
};

export default Items;
