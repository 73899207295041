import { cx } from 'linaria';
import React from 'react';

import Logo1 from '../../content/icons/logo-01.svg';
import Logo2 from '../../content/icons/logo-02.svg';
import Logo3 from '../../content/icons/logo-03.svg';
import Logo4 from '../../content/icons/logo-04.svg';

import { container, list, logo, logo3, logoBLM } from './logoGrid.style';
import maxWidth from '../../mixins/max-width.style';

const LogoGrid = () => (
  <div className={container}>
    <ul className={cx(list, maxWidth)}>
      <li>
        <Logo1 className={logo} />
      </li>
      <li>
        <Logo2 className={logo} />
      </li>
      <li>
        <Logo3 className={cx(logo, logo3)} />
      </li>
      <li>
        <Logo4 className={cx(logo, logoBLM)} />
      </li>
    </ul>
  </div>
);

export default LogoGrid;
