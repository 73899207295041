import React, { useState } from 'react';
import Image from '../Image';
import PdfDownload from '../PdfDownload';

import {
  container,
  flexRow,
  primage,
  prbody,
  prdate,
  prheader,
  prtext,
  readmore,
  wideonly,
  smallonly,
} from './pressRelease.style';

const PressRelease = ({
  image,
  date,
  header,
  shorttext,
  text,
  pdf,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <em className={container} {...props}>
      <div className={flexRow}>
        <div className={wideonly}>
          <Image name={image} className={primage} objectFit="contain" />
        </div>
        <div className={prbody}>
          <p className={prdate}>{date}</p>
          <div className={smallonly}>
            <Image name={image} className={primage} objectFit="contain" />
          </div>
          <p className={prheader}>{header}</p>
          {!visible && (
            <>
              <p>{shorttext}</p>
            </>
          )}
          {visible && <p className={prtext}>{text}</p>}
          <div className={flexRow}>
            <button
              type="button"
              className={readmore}
              onClick={() => setVisible(!visible)}
            >
              Read {visible ? 'less' : 'more'}
            </button>
            {visible && pdf && <PdfDownload pdf={pdf} />}
          </div>
        </div>
      </div>
    </em>
  );
};

export default PressRelease;
