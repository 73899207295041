import { css } from 'linaria';

import { colors, fonts } from '../../tokens';
import fluid from '../../mixins/fluid.style';

export const container = css`
  align-items: center;
  background-color: ${colors.darkGrey};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

export const blockquote = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.stencil};
  font-size: ${fluid(1.5, 2.25)};
  line-height: 1.15;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  width: 100%;
`;

export const author = css`
  display: block;
  color: ${colors.pink};
  font-family: ${fonts.sans};
  font-size: ${fluid(1, 1.4)};
  font-style: normal;
  margin-top: 0.5rem;
`;
