/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { cx } from 'linaria';
import React, { Suspense, lazy, useState } from 'react';

import Image from '../Image';

import maxWidth from '../../mixins/max-width.style';
import {
  container,
  backgroundImage as backgroundImageStyle,
  backgroundImageContainer as backgroundImageContainerStyle,
  contentContainer as contentContainerStyle,
  playButton as playButtonStyle,
} from './video.style';

const Embed = lazy(() => import('../Embed'));

const Video = ({ poster, background, youtubeVideoId = null }) => {
  const [isEnabled, setIsEnabled] = useState(false);

  return (
    <figure
      className={container}
      onClick={() => {
        if (youtubeVideoId) {
          setIsEnabled(true);
        }
      }}
    >
      <div className={backgroundImageContainerStyle}>
        <Image name={background} className={backgroundImageStyle} />
      </div>

      <div className={cx(contentContainerStyle, maxWidth)}>
        {!isEnabled ? (
          <>
            <Image
              name={poster}
              style={{ boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.3)' }}
            />

            {youtubeVideoId && (
              <button
                type="button"
                aria-label="Play the youtube video"
                onClick={() => setIsEnabled(true)}
                className={playButtonStyle}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.6 97.6">
                  <circle cx="48.8" cy="48.8" r="48.8" fill="#fff" />
                  <path fill="#ee74a2" d="M72.8 48.8L34.1 26.4v44.8z" />
                </svg>
              </button>
            )}
          </>
        ) : (
          <>
            {youtubeVideoId && (
              <Suspense fallback={<p>Loading video</p>}>
                <Embed id={youtubeVideoId} />
              </Suspense>
            )}
          </>
        )}
      </div>
    </figure>
  );
};

export default Video;
