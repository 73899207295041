import { css } from 'linaria';
import { colors } from '../../tokens';

export const container = css`
  display: flex;
  justify-content: center;
  background: ${colors.darkGrey};
  padding-top: 4rem;
`;

export const containerPassageToDark = css`
  position: relative;

  ::after {
    background-color: ${colors.darkGrey};
    content: '';
    height: 50%;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;
  }
`;

export const imageContainer = css`
  position: relative;
  z-index: 2;
`;
