/* eslint-disable import/prefer-default-export */

import { css } from 'linaria';

import { colors, fonts } from '../../tokens';

export const body = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const bodyWithoutHero = css`
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media (min-width: 400px) {
    padding-top: 12rem;
  }

  @media (min-width: 600px) {
    padding-top: 9rem;
  }

  @media (min-width: 1020px) {
    padding-top: 3rem;
  }
`;

export const bodyDark = css`
  background-color: ${colors.darkGrey};
  color: white;
  padding-bottom: 2rem;
  padding-top: 6rem;

  a {
    color: ${colors.white};
  }
`;

export const bodyHome = css`
  @media (min-width: 768px) {
    ul {
      margin: 1rem 0 2rem 1.5rem;
      > li {
        padding-left: 3rem;
      }
    }
  }
`;

/* This is necessary, so the surrounding blocks of the video are in front of
   the background image
*/
export const block = css`
  position: relative;
  z-index: 3;
`;

export const italic = css`
  font-style: italic !important;
  text-decoration: none !important;
`;

export const h2 = css`
  font-family: ${fonts.sans};
  font-size: 1.5rem;
  line-height: 1.11;
  font-weight: 400;
  margin: 1rem 0;

  @media (min-width: 800px) {
    font-size: 2.25rem;
  }
`;

export const h3 = css`
  margin: 1rem 0;
  color: ${colors.pink};
  font-family: ${fonts.sans};
  font-size: 1.2375rem;
  line-height: 1.11;
  font-weight: 700;

  @media (min-width: 800px) {
    font-size: 1.875rem;
  }
`;
