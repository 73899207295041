import { cx } from 'linaria';
import { onlyText } from 'react-children-utilities';
import React from 'react';
import slugify from 'slugify';

import {
  content,
  h2,
  h3,
  h3Inline,
  h4,
  maxWidthFix,
  anchor,
  leftOffset,
} from './headline.style';
import maxWidth from '../../../mixins/max-width.style';

const Headline = ({
  children,
  level,
  as,
  offsetLeft,
  standalone = false,
  ...props
}) => {
  const Tag = `h${level || '2'}`;
  const innerText = onlyText(children);
  const levelStyle = as || level;

  const id = slugify(innerText, {
    lower: true,
    remove: /[“”‘’'"?,]/g,
  });

  if (standalone) {
    return (
      <>
        <div className={anchor} id={id} />
        <div className={cx(maxWidth)}>
          <Tag
            className={cx(
              (levelStyle === '2' || levelStyle === '1') && h2,
              levelStyle === '3' && h3,
              levelStyle === '3' && !standalone && h3Inline,
              levelStyle === '4' && h4,
              standalone && maxWidth,
              standalone && maxWidthFix,
              offsetLeft && leftOffset
            )}
            {...props}
          >
            {levelStyle === '2' || levelStyle === '1' || levelStyle === '4' ? (
              <span className={content}>{children}</span>
            ) : (
              <>{children}</>
            )}
          </Tag>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={anchor} id={id} />
      <Tag
        className={cx(
          (levelStyle === '2' || levelStyle === '1') && h2,
          levelStyle === '3' && h3,
          levelStyle === '3' && !standalone && h3Inline,
          levelStyle === '4' && h4,
          standalone && maxWidth,
          standalone && maxWidthFix
        )}
        {...props}
      >
        {levelStyle === '2' || levelStyle === '1' || levelStyle === '4' ? (
          <span className={content}>{children}</span>
        ) : (
          <>{children}</>
        )}
      </Tag>
    </>
  );
};

export default Headline;
