import React from 'react';
import { cx } from 'linaria';

import {
  container as containerStyle,
  columns as columnsStyle,
} from './columns.style';

import maxWidth from '../../mixins/max-width.style';

export default function Columns({ firstCol, secondCol }) {
  return (
    <div className={containerStyle}>
      <div className={cx(columnsStyle, maxWidth)}>
        {firstCol}
        {secondCol}
      </div>
    </div>
  );
}
