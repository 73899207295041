import { css } from 'linaria';

const link = css`
  font-weight: bold;
  font-size: small;
  font-style: normal;
  cursor: pointer;
  background: none;
  width: max-content;
  border: none;
  margin-left: 40px;
`;

export default link;
