import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import React from 'react';

import { patchimage } from './shop.style';

const Patch = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "heropatch.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      mobile: file(relativePath: { eq: "heropatch_mobile.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `);

  const sources = [
    {
      ...data.mobile.childImageSharp.fluid,
      media: `(max-width: 768px)`
    },
    {
      ...data.desktop.childImageSharp.fluid,
      media: `(min-width: 768px)`
    }
  ]

  return (
    <div>
      <Img fluid={sources}
        objectFit="cover"
        objectPosition="50% 0%"
        className={patchimage}
      />
    </div>
  );
};

export default Patch;
