import { css } from 'linaria';
import { colors, fonts } from '../../tokens';

export const container = css`
  align-items: center;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
`;

export const containerOnImage = css`
  margin-top: -5rem;
`;

export const excerpt = css`
  color: black;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.stencil};
  font-size: 2.8rem;
  line-height: 1.11;
  font-weight: 400;
  margin-bottom: 2rem;
  margin-top: 0;
  text-shadow: 0 0 0.4rem white;
  padding-top: 4rem;

  @media screen and (min-width: 800px) {
    padding-top: 2rem;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 0;
  }
`;

export const child = css`
  color: ${colors.darkGrey};
  padding-left: 0;
  padding-right: 0;
`;
