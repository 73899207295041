import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';

import { figure as figureStyle, caption as captionStyle } from './image.style';

const Image = ({ name, caption, ...props }) => {
  const {
    allImageSharp: { images },
  } = useStaticQuery(graphql`
    query {
      allImageSharp {
        images: nodes {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  `);

  const image = images.find(
    ({ fluid: { originalName } }) => originalName === name
  );

  if (!image) {
    return null;
  }

  return (
    <figure className={figureStyle}>
      <GatsbyImage fluid={image.fluid} {...props} />
      {caption && <figcaption className={captionStyle}>{caption}</figcaption>}
    </figure>
  );
};

export default Image;
