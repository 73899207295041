import { MDXProvider } from '@mdx-js/react';
import { cx } from 'linaria';
import React from 'react';

import { components } from '../Body/Body';
import maxWidth from '../../mixins/max-width.style';
import {
  container,
  containerOnImage,
  child as childStyle,
  excerpt as excerptStyle,
} from './intro.style';

const Intro = ({ children, excerpt }) => (
  <>
    <div className={cx(container, containerOnImage)}>
      {excerpt && (
        <h1 className={cx(excerptStyle, maxWidth)}>
          <span>{excerpt}</span>
        </h1>
      )}
    </div>

    <div className={container}>
      <div className={cx(maxWidth, childStyle)}>
        <MDXProvider components={components}>{children}</MDXProvider>
      </div>
    </div>
  </>
);

export default Intro;
