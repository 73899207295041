import React from 'react';

import link from './pdfDownload.style';

const PdfDownload = ({ pdf }) => {
  return (
    <button
      type="button"
      className={link}
      onClick={() => window.open(pdf, '_blank')}
    >
      Download
    </button>
  );
};

export default PdfDownload;
