import Body from "../../../../src/components/Body";
import Columns from "../../../../src/components/Columns";
import Donate from "../../../../src/components/Donate";
import Hero from "../../../../src/components/Hero";
import Shop from "../../../../src/components/Shop";
import HeroFooter from "../../../../src/components/HeroFooter";
import ImageInline from "../../../../src/components/ImageInline";
import ImageGrid from "../../../../src/components/ImageGrid";
import Headline from "../../../../src/components/Body/Headline";
import Intro from "../../../../src/components/Intro";
import Quote from "../../../../src/components/Quote";
import LogoGrid from "../../../../src/components/LogoGrid";
import SARMessage from "../../../../src/components/SARMessage";
import Twitter from "../../../../src/components/Twitter";
import Video from "../../../../src/components/Video";
import * as React from 'react';
export default {
  Body,
  Columns,
  Donate,
  Hero,
  Shop,
  HeroFooter,
  ImageInline,
  ImageGrid,
  Headline,
  Intro,
  Quote,
  LogoGrid,
  SARMessage,
  Twitter,
  Video,
  React
};