import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { cx } from 'linaria';

import TwitterIcon from '../../content/icons/twitter.svg';
import InstagramIcon from '../../content/icons/instagram.svg';
import FacebookIcon from '../../content/icons/facebook.svg';

import {
  socialButtons,
  socialButton,
  instagramButton,
  facebookButton,
  twitterButton,
} from './socialButtons.style';

const SocialButtons = () => {
  const {
    site: {
      siteMetadata: { twitterURL, instagramURL, facebookURL },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          twitterURL
          instagramURL
          facebookURL
        }
      }
    }
  `);

  return (
    <div className={socialButtons}>
      <a
        href={instagramURL}
        aria-label="Follow LouiseMichel on instagram"
        className={cx(socialButton, instagramButton)}
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </a>

      <a
        href={facebookURL}
        aria-label="Follow LouiseMichel on facebook"
        className={cx(socialButton, facebookButton)}
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon />
      </a>

      <a
        href={twitterURL}
        aria-label="Follow LouiseMichel on twitter"
        className={cx(socialButton, twitterButton)}
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
      </a>
    </div>
  );
};

export default SocialButtons;
