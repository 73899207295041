import React from 'react';
import { cx } from 'linaria';

import Image from '../Image';
import maxWidth from '../../mixins/max-width.style';
import {
  container as containerStyle,
  containerPassageToDark as containerWithPassageToDarkStyle,
  imageContainer as imageContainerStyle,
} from './imageInline.style';

export default function ImageInline({ image, passageToDark = false, caption }) {
  return (
    <div
      className={cx(
        containerStyle,
        passageToDark && containerWithPassageToDarkStyle
      )}
    >
      <div className={cx(imageContainerStyle, maxWidth)}>
        <Image name={image} caption={caption} />
      </div>
    </div>
  );
}
