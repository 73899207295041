import { css } from 'linaria';
import { colors } from '../../tokens';

export const socialButtons = css`
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const socialButton = css`
  display: block;
  color: ${colors.pink};
  margin-right: 1.5rem;

  @media (min-width: 600px) {
    margin-right: 3.12rem;
  }

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: currentColor;
      transition: all 0.2s ease;
    }
  }

  :hover {
    color: ${colors.darkGrey};
  }
`;

export const instagramButton = css`
  width: 3.54rem;
  height: 3.54rem;

  @media (min-width: 1000px) {
    width: 5.37rem;
    height: 5.37rem;
  }
`;

export const facebookButton = css`
  width: 2.15rem;
  height: 4.02rem;

  @media (min-width: 1000px) {
    width: 3.27rem;
    height: 6.1rem;
  }
`;

export const twitterButton = css`
  width: 4.02rem;
  height: 3.27rem;

  @media (min-width: 1000px) {
    width: 6.1rem;
    height: 4.95rem;
  }
`;
