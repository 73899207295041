import { css } from 'linaria';
import { fonts } from '../../tokens';

export const shop = css`
  background-color: #fff;
`;

export const patchimage = css`
  top: -6rem;
  z-index: 0;
  @media screen and (max-width: 768px) {
    top: -2rem;
  }
`;

export const message = css`
  max-width: 70rem;
  margin: -4rem auto 1rem;
  padding: 0 0.8rem;
  font-family: ${fonts.sans};
  font-size: 1.4rem;
  line-height: 1.33;

  @media screen and (max-width: 768px) {
    margin: -2rem auto 1rem;
  }
`;

export const smallnote = css`
  font-size: 1.2rem;
`;

export const items = css`
  display: flex;
  flex-wrap: wrap;
  align-items: bottom;
  justify-content: center;
  margin: auto;
  padding-bottom: 2rem;
`;

export const itemimage = css`
  width: 45rem;
  max-width: 90vw;
  margin: 0.5rem;
`;

export const shirtcouple= css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const pricetag = css`
  font-family: ${fonts.sans};
  font-size: 1.4rem;
  line-height: 1.33;
  text-align: center;
`;

export const pricenote = css`
  font-family: ${fonts.sans};
  font-size: 1.0rem;
  margin-top: -1.25rem;
  text-align: center;
`;

export const link = css`
  text-decoration: none;
  color: inherit;
`;

export const itempatch = css`
  align-self: flex-end;
  flex-basis: 90rem;
  @media screen and (min-width: 300rem) {
    flex-basis: content;
  }
`;

