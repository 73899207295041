import React from 'react';
import { cx } from 'linaria';

import maxWidth from '../../mixins/max-width.style';

import {
  container as containerStyle,
  blockquote as blockquoteStyle,
  author as authorStyle,
} from './quote.style';

export default function Quote({ author, children }) {
  return (
    <div className={containerStyle}>
      <blockquote className={cx(blockquoteStyle, maxWidth)}>
        “{children}”
        {author && (
          <cite className={authorStyle}>
            <span>–</span> {author}
          </cite>
        )}
      </blockquote>
    </div>
  );
}
