import { css, cx } from 'linaria';

const base = css`
  max-width: 50rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  @media screen and (min-width: 1200px) {
    max-width: 70rem;
  }
`;

export default base;

export const wide = cx(
  base,
  css`
    max-width: 70rem;
  `
);
