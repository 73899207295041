import React from 'react';

import { list } from './ul.style';

const Ul = ({ children, ...props }) => (
  <ul className={list} {...props}>
    {children}
  </ul>
);

export default Ul;
