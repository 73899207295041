import Body from "../../../../src/components/Body";
import Headline from "../../../../src/components/Body/Headline";
import Donate from "../../../../src/components/Donate";
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import * as React from 'react';
export default {
  Body,
  Headline,
  Donate,
  AnchorLink,
  React
};