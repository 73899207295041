import { css } from 'linaria';

export const heroFooter = css`
  position: relative;
  height: 72vw;
  margin-top: -12vw;
`;

export const image = css`
  height: 100%;
`;
