import React from 'react';
import Patch from './Patch';
import Message from './Message';
import Items from './Items';

import { shop } from './shop.style';

const Shop = () => {
  return (
    <div className={shop}>
      <Patch />
      <Message />
      <Items />
    </div>
  );
};

export default Shop;
