import { cx } from 'linaria';
import React from 'react';

import { paragraph, small as smallStyle } from './Paragraph.style';

const Paragraph = ({ children, small = false, ...props }) => (
  <p className={cx(paragraph, small && smallStyle)} {...props}>
    {children}
  </p>
);

export default Paragraph;
