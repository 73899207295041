/* eslint-disable import/prefer-default-export */

import { css } from 'linaria';

import { colors, fonts } from '../../../tokens';

export const list = css`
  list-style: none;
  margin: 0 0 1rem;
  padding-left: 0;

  > * {
    font-family: ${fonts.sans};
    font-size: 1.4rem;
    line-height: 1.33;
    margin: 0 0 1rem;
    padding-left: 1rem;
    position: relative;

    @media (min-width: 800px) {
      padding-left: 2rem;
    }
  }

  > *:before {
    color: ${colors.pink};
    content: '—';
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
  }

  > * + * {
    margin-top: 1rem;
  }

  > * li:before {
    content: none;
  }

  ul {
    list-style: disc;
    padding-left: 1.5rem;

    @media (min-width: 800px) {
      padding-left: 4rem;
    }

    > * {
      padding-left: 0rem;

      @media (min-width: 800px) {
        padding-left: 1rem;
      }
    }
  }
`;
