import { css } from 'linaria';

import { colors, fonts } from '../../tokens';

export const text = css`
  background-color: ${colors.superDarkGrey};
  border: 2px solid ${colors.superDarkGrey};
  left: 50%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-5deg);
`;

export const button = css`
  position: fixed;
  bottom: 5rem;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  opacity: 0;
  font-family: ${fonts.stencil};
  font-size: 1.5rem;
  text-align: center;
  text-decoration: none;
  will-change: transform opacity;
  transform: scale(0);
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;

  @media screen and (min-width: 1400px) {
    font-size: 1.75rem;
    right: 2rem;
  }

  :hover,
  :focus {
    color: ${colors.superDarkGrey};
  }

  :hover > svg,
  :focus > svg {
    animation-play-state: paused;
  }

  :hover > span,
  :focus > span {
    background-color: ${colors.pink};
    border-color: ${colors.superDarkGrey};
    color: ${colors.superDarkGrey};
  }
`;

export const buttonVisible = css`
  opacity: 1;
  transform: scale(1);
`;

export const svg = css`
  @keyframes heartbeat {
    0% {
      transform: scale(0.95);
    }
    20% {
      transform: scale(1);
    }
    40% {
      transform: scale(0.95);
    }
    60% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.95);
    }
  }

  animation: heartbeat 1.25s infinite;
  height: 2rem;
  margin-right: 0.5rem;
  width: 2rem;
  will-change: transform;
  height: 6rem;
  width: 6rem;

  @media screen and (min-width: 1400px) {
    height: 10rem;
    width: 10rem;
  }
`;
