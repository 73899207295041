import { css } from 'linaria';

import { colors, fonts } from '../../tokens';

export const container = css`
  margin-bottom: 2.25rem;
  width: 100%;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const primage = css`
  width: 100%;

  @media screen and (min-width: 880px) {
    width: 400px;
  }
`;

export const prbody = css`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: ${fonts.sans};
  font-size: medium;
  font-style: normal;
`;

export const prdate = css`
  color: ${colors.pink};
  margin-top: 0;
  margin-bottom: 0.5rem;
  @media screen and (min-width: 880px) {
    margin-bottom: 0;
  }
`;

export const prheader = css`
  margin-bottom: -0.25rem;
  font-weight: bold;
`;

export const prtext = css`
  white-space: pre-wrap;
`;

export const readmore = css`
  font-weight: bold;
  font-size: small;
  cursor: pointer;
  background: none;
  width: max-content;
  border: none;
  padding: 0;
`;

export const wideonly = css`
  display: none;
  @media screen and (min-width: 880px) {
    display: flex;
  }
`;

export const smallonly = css`
  display: block;
  @media screen and (min-width: 880px) {
    display: none;
  }
`;
