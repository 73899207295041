import { css } from 'linaria';

import fluid from '../../mixins/fluid.style';
import { colors, fonts } from '../../tokens';

export const figure = css`
  height: 100%;
  margin: 0;
`;

export const caption = css`
  color: ${colors.pink};
  font-family: ${fonts.sans};
  font-size: ${fluid(0.8, 0.9)};
  padding-top: 0.5rem;
  text-align: right;
`;
