import React from 'react';

import { smallnote, message } from './shop.style';

const Message = () => {
  return (
    <div className={message}>
      <p>
        Our pink ship had a busy 2023 - directly providing life saving support to more than 700 people in distress in the Med. She spent the winter in the yard getting essential repairs carried out and has been back in the central Mediterranean sea since June. You can help to fund our work by buying some of our merch!
        <br />
        <br />
        Available now are T-Shirts and patches designed by Banksy (look closely and you'll see the 'lighthouse' is a spray can, natch).
        <br />
        <br />
        <span className={smallnote}>
          Love, rage and solidarity
          <br />
          – The Louise Michel, August 2024
        </span>
      </p>
    </div>
  );
};

export default Message;
