import { css } from 'linaria';

import { colors } from '../../tokens';

export const container = css`
  background-color: ${colors.darkGrey};
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 2rem;
`;

export const list = css`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  gap: 3rem;

  @media screen and (min-width: 1220px) {
    gap: 5rem;
  }
`;

export const logo = css`
  fill: #cfd4da;
  width: 4.752rem;
  height: 4.752rem;

  @media screen and (min-width: 1220px) {
    width: 7.2rem;
    height: 7.2rem;
  }
`;

export const logo3 = css`
  width: 4.08rem;
  height: 4.75rem;

  @media screen and (min-width: 1220px) {
    width: 6.18rem;
    height: 7.2rem;
  }
`;

export const logoBLM = css`
  width: 8.66rem;
  height: 3.71rem;

  @media screen and (min-width: 1220px) {
    width: 13.125rem;
    height: 5.625rem;
  }
`;
