import { useStaticQuery, graphql } from 'gatsby';
import { cx } from 'linaria';
import GatsbyImage from 'gatsby-image';
import React from 'react';

import { heroFooter, image as imageStyle } from './heroFooter.style';

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "footer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={cx(heroFooter)}>
      <GatsbyImage {...data.image.childImageSharp} className={imageStyle} />
    </div>
  );
};

export default Hero;
