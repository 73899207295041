import { css } from 'linaria';

import { colors, fonts } from '../../../tokens';

export const content = css`
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  position: relative;
  z-index: 2;
`;

export const h2 = css`
  color: ${colors.white};
  font-family: ${fonts.stencil};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.11;
  margin: 0 0 1.6rem -0.8rem;
  position: relative;

  @media (min-width: 800px) {
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }

  @media (max-width: 800px) {
    margin-top: 6rem;
  }

  span::after {
    content: '';
    background-color: ${colors.pink};
    height: 90%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-1deg);
    width: 100%;
    z-index: -1;
  }
`;

export const h3 = css`
  color: black;
  font-family: ${fonts.stencil};
  font-size: 1.49rem;
  line-height: 1.11;
  font-weight: 400;
  margin: 0 0 0.8rem;

  @media (min-width: 800px) {
    font-size: 2.25rem;
    margin-bottom: 1.2rem;
  }
`;

export const h3Inline = css`
  color: ${colors.pink};
`;

export const h4 = css`
  color: white;
  font-family: ${fonts.stencil};
  font-size: 1.49rem;
  line-height: 1.11;
  font-weight: 400;
  margin: 0 0 0.8rem;
  width: max-content;

  @media (min-width: 800px) {
    font-size: 2.25rem;
    margin-bottom: 1.2rem;
  }

  span::after {
    content: '';
    background-color: ${colors.darkGrey};
    height: 90%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-1deg);
    width: 100%;
    z-index: -1;
  }
`;

export const maxWidthFix = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const anchor = css`
  position: relative;
  top: -80px;
  visibility: hidden;
`;

export const leftOffset = css`
  @media (min-width: 1400px) {
    position: relative;
    left: -2rem;
  }
`;
