import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PressRelease from '../PressRelease';

import container from './pressReleaseList.style';

const List = () => {
  const data = useStaticQuery(graphql`
    {
      allPressreleasesYaml {
        edges {
          node {
            image
            header
            date
            shorttext
            text
            pdf {
              publicURL
            }
          }
        }
      }
    }
  `);
  return data.allPressreleasesYaml.edges.map((edge) => (
    <PressRelease
      image={edge.node.image}
      date={edge.node.date}
      header={edge.node.header}
      text={edge.node.text}
      shorttext={edge.node.shorttext}
      pdf={edge.node.pdf?.publicURL}
    />
  ));
};

const PressReleaseList = () => {
  return (
    <div className={container}>
      <List />
    </div>
  );
};

export default PressReleaseList;
