import { css } from 'linaria';

import { colors } from '../../tokens';
import fluid from '../../mixins/fluid.style';

export const container = css`
  display: flex;
  justify-content: center;
  margin: ${fluid(-3, -10)} 0 ${fluid(-10, -15)} 0;
  padding-bottom: ${fluid(15, 19)};
  padding-top: ${fluid(7, 14)};
  position: relative;
  width: 100%;
`;

export const backgroundImage = css`
  height: 100%;
`;

export const backgroundImageContainer = css`
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;

  ::before,
  ::after {
    content: '';
    height: 5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ::before {
    background-image: linear-gradient(to top, transparent, ${colors.darkGrey});
    top: 0;
  }

  ::after {
    background-image: linear-gradient(
      to bottom,
      transparent,
      ${colors.darkGrey}
    );
    bottom: 0;
  }
`;

export const playButton = css`
  background: transparent;
  border: 0;
  height: ${fluid(3, 6)};
  left: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${fluid(3, 6)};

  :hover,
  :focus {
    cursor: pointer;
  }

  :hover svg circle,
  :focus svg circle {
    fill: ${colors.pink};
  }

  :hover svg path,
  :focus svg path {
    fill: ${colors.white};
  }
`;

export const contentContainer = css`
  position: relative;
  z-index: 2;
`;
