// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-pages-contact-mdx": () => import("./../../../src/content/pages/contact.mdx" /* webpackChunkName: "component---src-content-pages-contact-mdx" */),
  "component---src-content-pages-faq-mdx": () => import("./../../../src/content/pages/faq.mdx" /* webpackChunkName: "component---src-content-pages-faq-mdx" */),
  "component---src-content-pages-imprint-mdx": () => import("./../../../src/content/pages/imprint.mdx" /* webpackChunkName: "component---src-content-pages-imprint-mdx" */),
  "component---src-content-pages-index-mdx": () => import("./../../../src/content/pages/index.mdx" /* webpackChunkName: "component---src-content-pages-index-mdx" */),
  "component---src-content-pages-press-mdx": () => import("./../../../src/content/pages/press.mdx" /* webpackChunkName: "component---src-content-pages-press-mdx" */),
  "component---src-content-pages-privacy-mdx": () => import("./../../../src/content/pages/privacy.mdx" /* webpackChunkName: "component---src-content-pages-privacy-mdx" */)
}

