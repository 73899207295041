import { MDXProvider } from '@mdx-js/react';
import { cx } from 'linaria';
import React, { forwardRef } from 'react';

import Paragraph from './Paragraph';
import Ul from './Ul';

import {
  body as bodyStyle,
  bodyHome as bodyHomeStyle,
  bodyDark as bodyDarkStyle,
  bodyWithoutHero as bodyWithoutHeroStyle,
  block as blockStyle,
  italic,
  h2,
  h3,
} from './body.style';
import maxWidth from '../../mixins/max-width.style';

export const components = {
  ul: (props) => (
    <div className={cx(blockStyle, maxWidth)}>
      <Ul {...props} />
    </div>
  ),

  small: (props) => (
    <div className={cx(blockStyle, maxWidth)}>
      <Paragraph small {...props} />
    </div>
  ),

  em: (props) => <em className={italic} {...props} />,

  p: (props) => (
    <div className={cx(blockStyle, maxWidth)}>
      <Paragraph {...props} />
    </div>
  ),

  h2: ({ children, ...props }) => (
    <div className={cx(blockStyle, maxWidth)}>
      <h2 className={h2} {...props}>
        {children}
      </h2>
    </div>
  ),

  h3: ({ children, ...props }) => (
    <div className={cx(blockStyle, maxWidth)}>
      <h3 className={h3} {...props}>
        {children}
      </h3>
    </div>
  ),
};

const Body = forwardRef(
  ({ withoutHero = false, children, dark, home }, ref) => (
    <div
      className={cx(
        bodyStyle,
        withoutHero && bodyWithoutHeroStyle,
        home && bodyHomeStyle,
        dark && bodyDarkStyle
      )}
      ref={ref}
    >
      <MDXProvider components={components}>{children}</MDXProvider>
    </div>
  )
);

export default Body;
