import { css } from 'linaria';

import { colors, fonts } from '../../tokens';

export const container = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 3.4rem;
  padding-bottom: 2.25rem;

  @media screen and (min-width: 600px) {
    padding-top: 5rem;
    padding-bottom: 3.5rem;
  }

  @media screen and (min-width: 880px) {
    padding-top: 6.8rem;
    padding-bottom: 4.5rem;
  }
`;

export const base = css`
  color: ${colors.white};
  display: block;
  font-family: ${fonts.stencil};
  font-size: 2rem;
  font-style: normal;
  line-height: 1;
  margin-bottom: 0.25rem;
  text-align: center;

  @media screen and (min-width: 600px) {
    font-size: 2.5rem;
  }
`;

export const strikeThrough = css`
  position: relative;

  ::after {
    border-top: 3px solid currentColor;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
  }
`;
