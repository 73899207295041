/* eslint-disable import/prefer-default-export */

import { css } from 'linaria';

import fluid from '../../mixins/fluid.style';

export const container = css`
  display: flex;
  justify-content: center;
`;

export const columns = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 10;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }

  > *:nth-child(2) {
    @media screen and (min-width: 1024px) {
      margin-top: 0;
      max-width: 45%;
      padding-left: ${fluid(1, 1.25)};
    }
  }
`;
