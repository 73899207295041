import { cx } from 'linaria';
import React from 'react';

import { container, base, strikeThrough } from './sarMessage.style';

const SARMessage = ({ previous, now, ...props }) => (
  <em className={container} {...props}>
    <span className={cx(base, strikeThrough)}>{previous}</span>
    <span className={base}>{now}</span>
  </em>
);

export default SARMessage;
