const Twitter = () => {
  // twitter (now x?) is broken
  return null;
};

/*
import { useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Headline from '../Body/Headline';
import Tweet from './Tweet';
import TwitterIcon from '../../content/icons/twitter.svg';
import InstagramIcon from '../../content/icons/instagram.svg';
import FacebookIcon from '../../content/icons/facebook.svg';

import {
  container,
  list,
  label as labelStyle,
  twitterIcon as twitterIconStyle,
  smIconsContainer,
} from './twitter.style';
import maxWidth from '../../mixins/max-width.style';

const Twitter = ({ label, title }) => {

  // twitter (now x?) is broken
  return null;

  const [tweets, setTweets] = useState(null);

  const {
    site: {
      siteMetadata: { twitterURL, facebookURL, instagramURL },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          twitterURL
          facebookURL
          instagramURL
        }
      }
    }
  `);

  useEffect(() => {
    fetch('/.netlify/functions/twitter-read')
      .then((res) => res.json())
      .then(setTweets);
  }, []);

  if (!tweets) {
    return null;
  }

  return (
    <div className={container}>
      <div className={maxWidth}>
        <Headline level="2">{title}</Headline>

        {tweets && (
          <ul className={list}>
            {tweets.map((tweet, index) => (
              <li key={tweet.created_at}>
                <Tweet {...tweet} transitionDelay={index * 300} />
              </li>
            ))}
          </ul>
        )}

        <p className={labelStyle}>{label}</p>

        <div className={smIconsContainer}>
          <a
            href={instagramURL}
            className={labelStyle}
            aria-label="Follow us on instagram"
          >
            <InstagramIcon className={twitterIconStyle} />
          </a>

          <a
            href={twitterURL}
            className={labelStyle}
            aria-label="Follow us on twitter"
          >
            <TwitterIcon className={twitterIconStyle} />
          </a>

          <a
            href={facebookURL}
            className={labelStyle}
            aria-label="Follow us on facebook"
          >
            <FacebookIcon className={twitterIconStyle} />
          </a>
        </div>
      </div>
    </div>
  );
};
*/

export default Twitter;
