/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { cx } from 'linaria';
import Modal from 'react-modal';
import React, { useState } from 'react';

import CloseIcon from '../../content/icons/times-circle.svg';
import EnlargeIcon from '../../content/icons/search-plus.svg';
import Image from '../Image';

import maxWidth from '../../mixins/max-width.style';

import {
  container,
  grid,
  row as rowStyle,
  imageGroup as imageGroupStyle,
  imageContainer,
  enlargeIcon as enlargeIconStyle,
  closeIcon as closeIconStyle,
  enlargeButton,
  closeButton,
  modal,
  modalOverlay,
} from './imageGrid.style';

const ImageWithContainer = ({ name, ...props }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  if (isEnlarged) {
    return (
      <Modal
        isOpen={isEnlarged}
        onRequestClose={() => {
          setIsEnlarged(false);
          document.body.removeAttribute('style');
        }}
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        shouldCloseOnOverlayClick
        className={modal}
        overlayClassName={modalOverlay}
      >
        <button
          type="button"
          aria-label="Close image"
          className={closeButton}
          onClick={() => {
            setIsEnlarged(false);
            document.body.removeAttribute('style');
          }}
        >
          <CloseIcon className={closeIconStyle} />
        </button>

        <Image
          style={{
            height: '100%',
          }}
          imgStyle={{
            boxShadow: '0 0 0.4rem black',
            height: 'auto',
            maxHeight: '100%',
            objectFit: 'contain',
            width: '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
          }}
          name={name}
        />
      </Modal>
    );
  }

  return (
    <div
      className={imageContainer}
      onClick={() => setIsEnlarged(true)}
      {...props}
    >
      <button
        type="button"
        aria-label="Enlarge image"
        className={enlargeButton}
        onClick={() => setIsEnlarged(true)}
      >
        <EnlargeIcon className={enlargeIconStyle} />
      </button>
      <Image name={name} {...props} />
    </div>
  );
};

const ImageGrid = ({ images, ...props }) => (
  <div className={container} {...props}>
    <ul className={cx(grid, maxWidth)}>
      {images.map((row) => (
        <li className={rowStyle}>
          {row.map((imageOrGroup) => (
            <>
              {Array.isArray(imageOrGroup) ? (
                <div className={imageGroupStyle}>
                  {imageOrGroup.map((image) => (
                    <ImageWithContainer name={image} />
                  ))}
                </div>
              ) : (
                <div>
                  <ImageWithContainer
                    name={imageOrGroup}
                    style={{ height: '100%' }}
                  />
                </div>
              )}
            </>
          ))}
        </li>
      ))}
    </ul>
  </div>
);

export default ImageGrid;
