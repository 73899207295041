import React, { useState, useContext } from 'react';
import { cx } from 'linaria';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import AppContext from '../../context';

import HeartSVG from '../../content/icons/heart.svg';

import {
  button as buttonStyle,
  buttonVisible as buttonVisibleStyle,
  svg,
  text as textStyle,
} from './donate.style';

const Donate = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { showDonateModal, setShowDonateModal } = useContext(AppContext);

  useScrollPosition(
    ({ currPos }) => {
      const windowHeight =
        window.innerHeight ||
        document.clientHeight ||
        document.body.clientHeight;

      setIsVisible(currPos.y * -1 > windowHeight);
    },
    [isVisible]
  );

  return (
    <button
      type="button"
      onClick={() => setShowDonateModal(!showDonateModal)}
      className={cx(buttonStyle, isVisible && buttonVisibleStyle)}
    >
      <HeartSVG className={svg} />
      <span className={textStyle}>{text}</span>
    </button>
  );
};

export default Donate;
