import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';

import { hero, image as imageStyle } from './hero.style';

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hero-spray.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={hero}>
      <GatsbyImage {...data.image.childImageSharp} className={imageStyle} />
    </div>
  );
};

export default Hero;
