/* eslint-disable import/prefer-default-export */

import { css } from 'linaria';

import { fonts } from '../../../tokens';

export const paragraph = css`
  font-family: ${fonts.sans};
  font-size: 1.4rem;
  line-height: 1.33;
  margin: 0 0 1rem;

  em {
    font-style: normal;
    text-decoration: underline;
  }

  code {
    font-family: ${fonts.sans};
  }
`;

export const small = css`
  font-size: 1.2rem;
  line-height: 1.33;
`;
